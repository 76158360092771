import styled from '@emotion/styled';

const Hover = styled('div')({
  bottom: 0,
  display: 'none',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  transitionTimingFunction: 'cubic-bezier(.7,0,.3,1)',
});

export default Hover;
