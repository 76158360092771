import styled from '@emotion/styled';

const ArticleList = styled('ul')({
  display: 'inline-block',
  listStyle: 'none',
  margin: 0,
  marginTop: '1.5rem',
  padding: 0,
  width: '100%',
});

export default ArticleList;
